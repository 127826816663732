var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-search-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "search-select v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "items": _vm.searchKeys,
      "item-text": "text",
      "item-value": "value"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--width-fixed v-input--density-compact",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "placeholder": "검색어를 입력하세요."
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-primary', {
    on: {
      "click": _vm.search
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('table', {
    staticClass: "board-list"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.fixed, function (download, index) {
    return _c('tr', {
      key: `fixed_${index}`
    }, [_c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${download._id}`);
        }
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")]), _c('span', [_vm._v(_vm._s(download.subject))])], 1)]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(download.createdAt.toDate()) + " ")]), download.upload ? _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('v-btn', {
      staticClass: "v-size--xx-small",
      attrs: {
        "href": download.upload,
        "download": download.upload.split('/').pop(),
        "icon": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": "/images/icon/icon-download.png"
      }
    })], 1)], 1) : _vm._e()]);
  }), _vm._l(_vm.downloads, function (download, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "board-list__tit",
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${download._id}`);
        }
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(_vm._s(download.subject))])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(download.createdAt.toDate()) + " ")]), download.upload ? _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('v-btn', {
      staticClass: "v-size--xx-small",
      attrs: {
        "href": download.upload,
        "download": download.upload.split('/').pop(),
        "icon": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": "/images/icon/icon-download.png"
      }
    })], 1)], 1) : _vm._e()]);
  })], 2)]), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "grey lighten-3 d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(880/1200*100%)"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(200/1200*100%)"
    }
  }, [_vm._v("작성일")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "calc(120/1200*100%)"
    }
  }, [_vm._v("다운로드")])])]);

}]

export { render, staticRenderFns }