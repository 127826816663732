<template>
    <div class="board-container">
        <div class="board-search-wrap">
            <v-row justify="end" class="row--xxs">
                <v-col cols="12" md="auto">
                    <v-select hide-details class="search-select v-input--density-compact" outlined :items="searchKeys" item-text="text" item-value="value" v-model="filter.searchKey" />
                </v-col>
                <v-col md="auto">
                    <v-text-field hide-details class="v-input--width-fixed v-input--density-compact" outlined placeholder="검색어를 입력하세요." v-model="filter.searchValue" />
                </v-col>
                <v-col cols="auto">
                    <btn-primary @click="search">검색</btn-primary>
                </v-col>
            </v-row>
        </div>

        <table class="board-list">
            <thead class="grey lighten-3 d-none d-lg-table-header">
                <tr>
                    <!-- <th style="width:10%;" class="board-list__txt d-none d-lg-table-cell">{{$t('common.number')}}</th> -->
                    <th style="width: calc(1000 / 1200 * 100%)" class="board-list__txt">{{ $t("common.제목") }}</th>
                    <th style="width: calc(200 / 1200 * 100%)" class="board-list__txt">{{ $t("common.작성일") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notification, index) in fixed" :key="`fixed_${index}`" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <!-- <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                    </td> -->
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <v-icon>mdi-alert-circle-outline</v-icon>
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ $dayjs(notification.createdAt).format("YYYY-MM-DD") }}
                    </td>
                </tr>

                <tr v-for="(notification, index) in notifications" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td class="board-list__tit">
                        <div class="ellip">
                            <span>{{ notification.subject }}</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ notification.createdAt.toDate() }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { mdiAlertCircleOutline } from "@mdi/js";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
export default {
    components: {
        BtnPrimary,
    },
    props: {
        value: { type: String, default: null },
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: {
                isNotice: false,
                searchKey: null,
                searchValue: null,
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            fixed: [],
            notifications: [],

            searchKeys: [
                { text: "전체", value: null },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }
                var { notifications: fixed } = await api.v1.center.notifications.gets({
                    headers: {
                        limit: 0,
                    },
                    params: { isNotice: true },
                });
                this.fixed = fixed;

                var { summary, notifications } = await api.v1.center.notifications.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });
                this.notifications = notifications;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
    .d-lg-table-header {
        display: table-header-group !important;
    }
}
</style>
